// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-actus-js": () => import("./../../../src/pages/actus.js" /* webpackChunkName: "component---src-pages-actus-js" */),
  "component---src-pages-des-questions-js": () => import("./../../../src/pages/des-questions.js" /* webpackChunkName: "component---src-pages-des-questions-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-les-oasis-js": () => import("./../../../src/pages/les-oasis.js" /* webpackChunkName: "component---src-pages-les-oasis-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

